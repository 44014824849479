import React from "react"
import BriefCaseSearchIcon from "mdi-react/BriefCaseSearchIcon"

import FoodAppleIcon from "mdi-react/FoodAppleIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import mobile from "../../images/components/mobile.jpg"
import mobileBody1 from "../../images/components/mobile-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./mobile-phone-use-training-for-seniors.scss"

var MobilePhoneUseTrainingForSeniors = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="mobile-phone-use-training-for-seniors-page">
      <Banner
        src={mobile}
        title="Mobile Phone-Use Training for Seniors"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Mobile Phone-Use Training for Seniors
        </BorderTitle>
        <div className="body">
          <img className="left" src={mobileBody1} alt="" /><p>As technology continues to advance at the speed of light, we want to make sure that our participating seniors have the knowledge on how to use their phones to the fullest.  This does not just mean for texting and calling but also learning how to customize their phones to make them accessible for their needs.</p><p>In our mobile training program, each person receives a coach that will walk them through how to text, call, and personalize their phone.  Additionally, our program will also teach our seniors on how to avoid scams and protect their personal information.  By doing so, we hope to empower our participants and allow them to have another way to connect with others.</p>
        </div>
        <QuoteImages />
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-one/church-based-food-pantry-program"
            className="first"
            icon={FoodAppleIcon}
            title="Church-Based Food Pantry Program"
            />
          <ComponentNavigator
            next
            link="/components-year-one/job-training-and-placement-program"
            icon={BriefCaseSearchIcon}
            title="Job Training and Placement Program"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default MobilePhoneUseTrainingForSeniors
